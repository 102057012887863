<template>
    <div class="p-2 h-100 w-100" v-if="show">
        <div class="d-flex justify-content-between align-items-center">
            <p class="sign-heading">Sign Document</p>
            <feather-icon class="cursor-pointer" icon="XIcon" size="18" @click="closeSignature"></feather-icon>
        </div>
        <div class="my-1" v-if="!loading && signature.imgUrl">
            <div class="signImg">
                <b-overlay :show="loading" class="w-100 h-100">
                    <img :src="signature.imgUrl" alt="" @load="disabledBtn=false" class="w-100 h-100">
                </b-overlay>
            </div>
            <div class="">
                    <b-button variant="primary" :disabled="disabledBtn" @click="signDocument" class="mt-1">Sign</b-button>
                    <b-spinner variant="primary" v-if="signDocLoading" class="ml-1 align-middle"></b-spinner>
            </div>
           
        </div>
        <div class="signature-card p-1">
            <div class="small-heading mb-1">Signatures</div>
            <p class="small-text mb-1">Your Signatures are saved in Settings. You can change your signatures in Settings</p>
            <router-link :to="{path:'/settings',query:{tab:'signature'}}" class="small-text text-primary text-underline">Open Settings</router-link>
        </div> 

        <div class="mt-5">
            <b-button variant="primary"  :disabled="disableSubmitBtn" @click="submitSignature()">Submit</b-button>
        </div>  
   </div>
</template>
<script>
import {BButton,BOverlay, BSpinner} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue';
import {get_signature} from '@/apis/others/user'
export default {
    components:{
        BButton,
        AppSimpleButton,
        BOverlay,
        BSpinner
    },
    data(){
        return {
         show:false,
         signature:{
            imgUrl:'',
            imgType:''
         },
         disabledBtn:true,
         disableSubmitBtn:true,
         loading:false,
         signDocLoading:false   
        }
    },
    methods:{
        showSignature(){
            this.show=true;
            this.getSignature()
        },
        closeSignature(){
            this.show=false;
            this.$emit('close');
        },
        getSignature(){
            this.loading=true;
            get_signature().then(res=>{
                this.loading=false;
                this.signature.imgUrl=res.data.url;
                this.signature.imgType=res.data.fileType;
            }).catch(err=>{
                this.loading=false;
            })
        },
        signDocument(){
            this.disabledBtn=true;
            this.signDocLoading=true;
            this.$emit('sign',this.signature);
        },
        submitSignature(){
            this.disableSubmitBtn=true;
            this.$emit('submit');
        },
        documentSigned(){
            this.signDocLoading=false;
            this.disableSubmitBtn=false;
        }
    }
}
</script>
<style scoped>
.sign-heading{
    font-weight: 500;
    size:16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 0px;
}
.signature-card{
    border-radius: 6px;
    background-color: #f8fafc;
}
.small-heading{
    line-height: 16px;
    font-weight: 400;
    size:13px;
    color: #000000;

}
.small-text{
    line-height: 15px;
    font-weight: 400;
    size:12px;
    color: #4B465C;
}
.text-underline{
    border-bottom: 1px solid #004bff;
    padding-bottom: 4px;
    
}
.signImg{
    border-radius: 6px;
    background-color: #f8fafc;
    width: 100%;
    height: 160px;
}
.submit-btn{
    position: absolute;
    bottom: 20px;
}
</style>