<template>
    <document-viewer-2 ref="docViewer" @currentPage="(p)=>$emit('currentPage',p)" :default-signature-boxes="defaultSignatureBoxes" :initialDoc="pdfDocument"/>
</template>
<script>

import DocumentViewer2 from "./DocumentViewer2.vue"
import { PDFDocument} from 'pdf-lib';
import {sign_document} from '@/apis/others/documents'
export default {
  props: { 
    initialDoc: { type: String },
    defaultSignatureBoxes:[Array],
   },
  components: {
    DocumentViewer2
  },
  data() {
    return {
     show:false,
     pdfDocument:'',
     pdfBytes:'',
     signaturePlacement:[],
     width:0,
     height:0,
    }
  },
  mounted() {
    this.modifyPdf()
    // console.log('vue',this.initialDoc, pdf)
  },
  methods: {
    async modifyPdf() {
      const url = this.initialDoc
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const pdfBytes= await pdfDoc.save()
      let base64String = btoa(
          new Uint8Array(pdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
      );
      this.pdfDocument='data:application/pdf;base64,' + base64String
    },
    addSignatureArea(details){

      this.$refs.docViewer.addSignatureBox(details)
    },
    setReceiverSign(data){
      this.signaturePlacement=[data];
      this.$refs.docViewer.currentPage=data.pageNo;
      this.$refs.docViewer.setReceiverSignArea(data)
    },
    async signDoc(signature){
      const url = this.initialDoc
      const existingPdfBytes = await fetch(url, {cache: "no-cache"}).then(res => res.arrayBuffer())

      const pdfDoc = await PDFDocument.load(existingPdfBytes)

      const pages = pdfDoc.getPages()
      const currentPage = pages[this.signaturePlacement[0].pageNo-1]
      const pageSize= currentPage.getSize()
      const imageUrl = signature.imgUrl;
      const imageBytes = await fetch(imageUrl, {cache: "no-cache"}).then((res) => res.arrayBuffer());
      const image =signature.imgType=='image/png'? await pdfDoc.embedPng(imageBytes):await pdfDoc.embedJpg(imageBytes);
      const { width, height } = image.scale(1);
      const signatureXDiff=this.signaturePlacement[0].canvasWidth/this.signaturePlacement[0].x
      const signatureYDiff=this.signaturePlacement[0].canvasHeight/this.signaturePlacement[0].y
      currentPage.drawImage(image, {
          x: pageSize.width/signatureXDiff,
          y:pageSize.height-pageSize.height/signatureYDiff-100,
          width:170,
          height:100
        })
      const pdfBytes= await pdfDoc.save()
 
      this.pdfBytes=pdfBytes;
      let base64String = btoa(
          new Uint8Array(pdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
      );
      this.pdfDocument='data:application/pdf;base64,' + base64String
      if(base64String){
        this.$emit('pdfSigned')
      }
      
    },
    submitSignature(){
      const blob = new Blob([this.pdfBytes], { type: 'application/pdf' });
      let data= new FormData();
      data.append('file',blob);
      sign_document(this.$route.query.id, data).then((resp)=>{
        this.$bvToast.toast(resp.message, {
              title: 'Signed successfully',
              variant: 'success',
              solid: true,
            })
        setTimeout(()=>this.$router.push('/documents'),[1000])
      })
    },
    getAxis(c,v,w){
      if(c<v){
        return c
      }else{
        return c-v;
      }
    },
    getYAxis(h,c,v){
      if(c<v){
        return h-c-v;
      }else if(c<h/2){
        return h-c-v/2;
      }else{
        return h-c;
      }
    },
  }
}
</script>